import React from "react";
import moment from "moment";
import numWords from "num-words";

import "./About.css";

const About = () => {
  const now = moment();
  const carlos = moment("1994-02-25");
  const carlosAge = numWords(now.diff(carlos, "years"));
  return (
    <div className="About">
      <p className="wow fadeIn" data-wow-delay="0.3s">
        I'm a software engineer and economist originally from Mexico, currently
        living in Austin, Texas. I'm {carlosAge} years old and currently working
        for Udemy. In December 2016, I graduated from The University of Texas at
        Austin with a double major in Computer Science and Economics, and have
        been working in the tech industry ever since. I've had the opportunity
        to work as a software engineer at Udemy and as a software developer at
        IBM, where I contributed to various exciting projects.
      </p>
      <p className="wow fadeIn" data-wow-delay="0.5s">
        My passion for computer science stems from my desire to solve complex
        problems and create innovative solutions using technology. I have
        experience working on a wide range of projects, from developing software
        applications to building and maintaining large-scale systems. I am
        always eager to learn new things and stay up-to-date with the latest
        trends and technologies in the field.
      </p>
      <p className="wow fadeIn" data-wow-delay="0.8s">
        Outside of work, I'm a huge horror fan and love exploring the genre
        through movies, books, and podcasts. I'm also passionate about
        technology and always looking for ways to stay up-to-date with the
        latest trends and developments in the field. In my free time I enjoy
        building websites and apps, playing basketball, and spending time with
        my wife 👧, and our two cats, Nelly and Jynx 🐱.
      </p>
      <p className="wow fadeIn" data-wow-delay="1s">
        P.s Go check{" "}
        <a
          href="https://magicandmeringue.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Magic & Meringue
        </a>{" "}
        to order amazing custom cakes and cupcakes from the talended Megan
        Goldstein.
      </p>
    </div>
  );
};

export default About;
