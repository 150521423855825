import React from "react";
import ChromeDinoGame from "react-chrome-dino";

import "./404.css";

const NotFound = () => {
  return (
    <div className="NotFound">
      <div className="heading">
        <h1 className="main-heading wow fadeIn" data-wow-delay="0.1s">
          You found me - Let's play a game!
        </h1>
        <p className="main-heading wow fadeIn" data-wow-delay="0.2s"></p>
        <p className="main-heading wow fadeIn" data-wow-delay="0.3s">
          <code className="wow fadeIn" data-wow-delay="0.4s">
            console.log(`Press space bar to start playing!{" "}
            <span role="img" aria-label="video game monster dinosaur joystick">
              🎮 👾 🦖 🕹
            </span>
            `);
          </code>
        </p>
        <div className="main-heading wow fadeIn" data-wow-delay="0.6s">
          <ChromeDinoGame />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
