import React, { Component } from "react";
import { withRouter } from "../App/App";
import WOW from "wowjs";
import Card from "./Card/Card";
import { Cards, Filters } from "./Items";

import "./Portfolio.css";

class Portfolio extends Component {
  constructor(props) {
    super();

    this.delay = props.delay || 0;
    this.filters = props.filters || Filters;
    const lastPath =
      props.lastLocation &&
      props.lastLocation.lastLocation &&
      props.lastLocation.lastLocation.pathname;
    this.animate =
      lastPath && lastPath.includes("/portfolio")
        ? ""
        : "wow fadeIn";

    this.getCards = this.getCards.bind(this);
    this.changeFilter = this.changeFilter.bind(this);

    const filter = props.filter || "Companies";
    this.state = {
      filter,
      cards: this.getCards(filter),
    };
  }

  componentDidMount() {
    const wow = new WOW.WOW({
      live: false,
      mobile: false,
    });
    wow.init();
  }

  getCards(filter) {
    const cards = filter
      ? Cards.filter((card) => card.tags && card.tags.includes(filter))
      : Cards;

    return cards
      .slice(0, 6)
      .map((card, i) => (
        <Card {...card} index={i} delay={this.delay} animate={this.animate} />
      ));
  }

  changeFilter(event) {
    if (this.state.filter === event.target.innerText) {
      this.setState({
        filter: null,
        cards: null,
      });

      setTimeout(() => {
        this.setState({ cards: this.getCards() });
      }, 20);
    } else {
      const filter = event.target.innerText;
      this.setState({
        filter,
        cards: null,
      });

      setTimeout(() => {
        this.setState({ cards: this.getCards(filter) });
      }, 20);
    }
  }

  render() {
    const { filter, cards } = this.state;

    const filters = this.filters.map((f, i) => {
      const selected = filter === f ? "selected" : "";
      return (
        <button
          key={i}
          className={`filter ${this.animate} ${selected}`}
          onClick={this.changeFilter}
          style={{
            visibility: "visible",
            animationDelay: 0.1 * i + "s",
          }}
        >
          {f}
        </button>
      );
    });

    const filterGroup = filters.length ? (
      <div className="filters">{filters}</div>
    ) : (
      ""
    );

    return (
      <div className="Portfolio">
        {filterGroup}
        {cards}
      </div>
    );
  }
}

export default withRouter(Portfolio);
