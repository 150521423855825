import React from "react";
import { Route, Routes } from "react-router-dom";
import Intro from "../Intro/Intro";
import About from "../About/About";
import Portfolio from "../Portfolio/Portfolio";
import Overlay from "../Portfolio/Overlay/Overlay";
import Contact from "../Contact/Contact";
import NotFound from "../404/404";
import Layout from "../Layout/Layout";

import "./Content.css";

const Content = () => {
  return (
    <div className="Content">
      <div className="container">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Intro />} />
            <Route path="/about" exact={true} element={<About />} />
            <Route path="/portfolio" exact={true} element={<Portfolio filter="All" filters={[]} />} />
            <Route path="/portfolio/:item" element={<Overlay />} />
            <Route path="/contact" exact={true} element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

export default Content;
