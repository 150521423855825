import React from "react";

const route = "zello";
const color = "rgb(233, 93, 47)";

const Zello = () => {
  return (
    <div className="OverlayItem Zello">
      <h1 style={{ color }}>Zello</h1>
      <p className="position">
        <b>Software Developer Intern</b>, May 2014 - December 2014
      </p>
      <p className="description">
        <a
          style={{ color }}
          href="https://zello.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zello
        </a>{" "}
        is the #1 rated digital walkie-talkie app&nbsp;
        <span role="img" aria-label="mobile phone emoji">
          📱
        </span>{" "}
        with over 170 million users in over 200 countries. Stay connected, safe,
        and productive by turning any smart device into a push-to-talk
        walkie-talkie with unlimited secure, private channels, message replay,
        location tracking, and emergency alerts. Zello’s clear, instant, live
        voice works over Wi-Fi and cell networks anywhere in the world.
      </p>
      <p className="description">
        As a Software Developer Intern, I was in charge of integrating a QR-code
        scanner/generator capability into Zello's Android application using Java
        and Android Studio
      </p>
      <p className="description no-justify">
        Some of the main responsibilities include:
      </p>
      <p className="description no-justify">
        <br />• Researched, implemented and tested an array of public QR-code
        APIs to provide a recommendation to the Project Team Leader
        <br />• Designed the user interface of the QR-code scanner and
        redesigned portions of Zello’s mobile application interface to
        incorporate the QR-code generation and download features
        <br />• Updated and released Zello’s Android application successfully to
        Google Play with the QR-code scanner/generator feature
        <br />• Managed project design, development, and testing teams
        simultaneously by prioritizing and delegating tasks, ensuring high
        quality, on-time, and on-budget project delivery
      </p>
    </div>
  );
};

const zelloObject = {
  route,
  component: Zello,
  card: {
    route,
    key: route,
    color,
    title: "Zello",
    tags: ["All", "Companies", "Mobile"],
    badge: "Company - Mobile",
    description: "Software Developer",
  },
};

export default zelloObject;
