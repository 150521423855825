import React from "react";
import { Link } from "react-router-dom";
import Portfolio from "../Portfolio/Portfolio";

import "./Intro.css";

const Intro = () => {
  return (
    <div className="Intro">
      <h3 className="section-title wow fadeIn">Recent Work</h3>
      <Portfolio filter="Latest" filters={[]} />
      <div className="more">
        <Link
          className="button wow fadeIn"
          data-wow-delay="1.3s"
          to="/portfolio"
        >
          View More
        </Link>
      </div>
    </div>
  );
};

export default Intro;
