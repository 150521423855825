import React from "react";

const route = "key";
const color = "#3fb562";

const Key = () => {
  return (
    <div className="OverlayItem Key">
      <h1 style={{ color }}>Key</h1>
      <p className="position">
        <b>Social media connection app (React Native & Android)</b>
      </p>
      <p className="icon">
        <span role="img" aria-label="key emoji">
          🗝️
        </span>
      </p>
      <p className="description">
        During the fall semester of 2016, while taking an Android class at The
        University of Texas, I initiated a project called Key. Key is a social
        media application that enables users to connect with others on multiple
        social media accounts simultaneously, through QR code scanning or
        sharing.
      </p>
      <p className="description">
        Users can create a QR code that includes data from their desired social
        media accounts. When another user scans the QR code, they are given the
        option to connect with the person on all the social media platforms they
        have included. Key simplifies the process of connecting with people as
        it eliminates the need for searching and finding different usernames for
        each platform.
      </p>
      <p className="description">
        {" "}
        Furthermore, Key allows users to choose which platforms they feel
        comfortable sharing, eliminating the guesswork involved in
        distinguishing between business and personal accounts or family and
        friends. For instance, if you meet someone new at a convention, you can
        share only the platforms you feel comfortable connecting on, and the
        other person can decide which options suit them best.
      </p>
    </div>
  );
};

const keyObject = {
  route,
  component: Key,
  card: {
    route,
    key: route,
    color,
    title: "Key",
    tags: ["All", "Mobile"],
    badge: "Project - Mobile",
    description: "Social media connection app",
  },
};

export default keyObject;
