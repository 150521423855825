import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faJ,
  faK,
  faQ,
  faI,
  faU,
  faZ,
  faHeart,
  faDiamond,
} from "@fortawesome/free-solid-svg-icons";

import "./Card.css";

const white = ["white", "#ffffff", "#f8f8ff"];

function getIcon(title) {
  let icon;
  switch (title) {
    case "IBM":
      icon = faI;
      break;
    case "Zello":
      icon = faZ;
      break;
    case "Quant":
      icon = faQ;
      break;
    case "Udemy":
      icon = faU;
      break;
    case "Key":
      icon = faK;
      break;
    case "Joker":
      icon = faJ;
      break;
    default:
      icon = faK;
      break;
  }
  return <FontAwesomeIcon icon={icon} size={"sm"} />;
}

function getRandomType(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  const item = arr[randomIndex];
  return item;
}

class Card extends Component {
  render() {
    const delay = this.props.delay || 0;
    const index = this.props.index || 0;
    const icon = this.props.icon ? "hasIcon" : "";
    const path = `/portfolio/${this.props.route}`;
    const animate = this.props.animate;
    const textWrapperColor = white.includes(this.props.color)
      ? "white"
      : "color";
    const arrayOfSuits = [faHeart, faDiamond];
    const type = getRandomType(arrayOfSuits);

    return (
      <Link
        className={`Card ${animate} ${this.props.title}`}
        style={{
          backgroundColor: this.props.color,
          visibility: "visible",
          animationDelay: delay + 0.1 * index + "s",
        }}
        to={path}
      >
        <div className={`wrapper ${icon}`}>
          <div className="topLeft">{getIcon(this.props.title)}</div>
          {this.props.title !== "Joker" && (
            <div className="topLeftBottom">
              <FontAwesomeIcon icon={type} size={"sm"} />
            </div>
          )}
          {this.props.badge && (
            <div className="topRight">
              <span className="badge">{this.props.badge}</span>
            </div>
          )}
          <div className="bottomRight">{getIcon(this.props.title)}</div>
          {this.props.title !== "Joker" && (
            <div className="bottomRightTop">
              <FontAwesomeIcon icon={type} size={"sm"} />
            </div>
          )}
          {this.props.title !== "Joker" && (
            <div className="innerContent">
              <span className={`title ${this.props.title}`}>
                {this.props.title}
              </span>
              <div className={`textWrapper ${textWrapperColor}`}>
                <p>{this.props.description}</p>
              </div>
            </div>
          )}
        </div>
      </Link>
    );
  }
}

export default Card;
