import React from "react";

const route = "quant";
const color = "#373079";

const Quant = () => {
  return (
    <div className="OverlayItem Quant">
      <h1 style={{ color }}>Quant</h1>
      <p className="position">
        <b>Stock Portfolio Modeling</b>
      </p>
      <p className="icon">
        <span role="img" aria-label="key emoji">
          💸
        </span>
      </p>
      <p className="description">
        Check it out{" "}
        <a
          style={{ color }}
          href="https://github.com/jtrejo13/quant"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </p>
    </div>
  );
};

const quantObject = {
  route,
  component: Quant,
  card: {
    route,
    key: route,
    color,
    title: "Quant",
    tags: ["All", "Full Stack"],
    badge: "Project - Full Stack",
    description: "Stock Portfolio Modeling",
  },
};

export default quantObject;