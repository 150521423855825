import React, { Component } from "react";
import { withRouter } from "../App/App";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import "./Navigation.css";

class Navigation extends Component {
  constructor(props) {
    super();
    this.activeLink = this.activeLink.bind(this);
  }

  activeLink(link) {
    const path =
      this.props && this.props.location && this.props.location.pathname;
    return path && path.startsWith(link.href) ? "active" : "";
  }

  render() {
    const links = [
      { href: "/about", title: "About" },
      { href: "/portfolio", title: "Portfolio" },
      { href: "/contact", title: "Contact" },
    ];

    return (
      <div className="Navigation">
        <Navbar expand="md" bg="dark" variant="dark">
          <Nav.Link href="/">
            <Navbar.Brand className="signature">Carlos Trejo</Navbar.Brand>
          </Nav.Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav onSelect={this.closeNavbar}>
              {links.map((link, i) => (
                <Nav.Link
                  key={i}
                  className={`navLinkItem ${this.activeLink(link)}`}
                  href={link.href}
                >
                  {link.title}
                </Nav.Link>
              ))}
              <a className="navLinkItem" href={process.env.PUBLIC_URL + '/resume-carlos-trejo.pdf'} target="_blank">
                Resume
              </a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(Navigation);
