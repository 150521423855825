import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  LastLocationProvider,
  useLastLocation,
} from "react-router-dom-last-location";
import Content from "../Content/Content";
import WOW from "wowjs";

import "./App.css";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    const history = useNavigate();
    const location = useLocation();
    const lastLocation = useLastLocation();
    return (
      <Children
        {...props}
        match={match}
        history={history}
        location={location}
        lastLocation={lastLocation}
      />
    );
  };
}

const App = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div className="App">
      <Router>
        <LastLocationProvider>
          <Content />
        </LastLocationProvider>
      </Router>
    </div>
  );
};

export default App;
