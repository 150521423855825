import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import "./Footer.css";

const Footer = () => {
  const size = "2x";
  return (
    <div className="Footer">
      <a
        className="wow fadeIn"
        data-wow-delay="0.2s"
        href="https://github.com/carlosdtrejo/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} size={size} />
      </a>
      <a
        className="wow fadeIn"
        data-wow-delay="0.4s"
        href="https://www.linkedin.com/in/carlostrejomtz/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedinIn} size={size} />
      </a>
      <a
        className="wow fadeIn"
        data-wow-delay="0.6s"
        href="https://twitter.com/carlgoldme/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} size={size} />
      </a>
    </div>
  );
};

export default Footer;
