import React from "react";

const color = "rgb(31,112,193)";
const route = "ibm";

const Ibm = () => {
  return (
    <div className="OverlayItem Ibm">
      <h1 style={{ color }}>IBM</h1>
      <p className="position">
        <b>Software Developer II</b>, January 2017 - October 2021
      </p>
      <p className="position">
        <b>Software Developer Intern</b>, May 2016 - December 2017
      </p>
      <p className="description">
        After graduating from the University of Texas in December 2016, I joined{" "}
        <a
          style={{ color }}
          href="https://www.ibm.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          IBM
        </a>{" "}
        full-time as a software developer on the Global Storage Architecture
        team.{" "}
        <a
          style={{ color }}
          href="https://www.ibm.com/blogs/cloud-computing/2013/02/22/a-storage-cloud-ahead-of-its-time/"
          target="_blank"
          rel="noopener noreferrer"
        >
          GSA
        </a>{" "}
        provides both sharable file storage for IBM employees to use for project
        activities and a highly available and scalable network-attached storage
        environment for many of their key business applications. IBMers use this
        cloud service to share files with project teams and store secondary
        copies of key files located on their laptops/desktops.
      </p>
      <p className="description no-justify">
        Some of the main responsibilities include:
      </p>  
      <p className="description no-justify">
        <br />• Developed internal the new GSA Cloud application from the ground
        up using agile methodology and involve in full development cycle from
        concept to production using Perl, JavaScript, jQuery, HTML, and CSS.
        <br />• Managed the software code base, revisions and versioned files
        such as source code, web files and documentation by utilizing both
        centralized code versioning system platforms Apache Subversion and Git
        for future reference and updates
        <br />• Engineered solution architecture and developed external
        interfaces to system using Common Gateway Interface modules along with
        web development practices, facilitating migration of business data
        across legacy systems
        <br />• Collaborated with stakeholders to identify and evaluate complex
        functional programming requirements and deliver related software
        applications to facilitate business
      </p>
    </div>
  );
};

const ibmObject = {
  route,
  component: Ibm,
  card: {
    route,
    key: route,
    color,
    title: "IBM",
    tags: ["All", "Latest", "Companies", "Full Stack"],
    badge: "Company - Full Stack",
    description: "Software Developer II",
  },
};

export default ibmObject;

