import React, { Component } from "react";
import { withRouter } from "../../App/App";
import { Components } from "../Items";
import { Link } from "react-router-dom";

import WOW from "wowjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./Overlay.css";

class Overlay extends Component {
  constructor(props) {
    super();

    this.item = Components[props.match.params.item] || Components.default;
    this.goBack = this.goBack.bind(this);
    this.escapeFn = this.escapeFn.bind(this);
  }

  escapeFn(e) {
    if (e.keyCode === 27) {
      this.goBack();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();

    document.addEventListener("keyup", this.escapeFn, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.escapeFn, false);
  }

  goBack() {
    this.props.history(-1);
  }

  render() {
    const backButton = (
      <div className="more">
        <Link className="button wow fadeIn" data-wow-delay="0.8s" to="/portfolio">
          <span className="backArrow">
            <FontAwesomeIcon icon={faArrowLeftLong} size={"lg"} />
          </span>{" "}
          Back to Portfolio
        </Link>
      </div>
    );

    return (
      <div className="Overlay wow fadeIn">
        {this.item}
        {backButton}
      </div>
    );
  }
}

export default withRouter(Overlay);
