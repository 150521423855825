import React from "react";
import Typist from "react-typist";

import "./Contact.css";

const Contact = () => {
  return (
    <div className="Contact">
      <div className="heading">
        <h1 className="main-heading wow fadeIn" data-wow-delay="0.4s">
          <Typist
            cursor={{
              element: "_",
            }}
            avgTypingDelay={200}
          >
            Let's chat!
          </Typist>{" "}
        </h1>
      </div>
      <div className="form wow fadeIn" data-wow-delay="0.4s">
        <form action="https://formspree.io/mgebkyaq" method="POST">
          <label
            className="field wow fadeIn"
            data-wow-delay="0.5s"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="input wow fadeIn"
            data-wow-delay="0.5s"
            maxLength="256"
            name="name"
            placeholder="What's your name?"
            type="text"
          />
          <label
            className="field wow fadeIn"
            data-wow-delay="0.7s"
            htmlFor="email"
          >
            Email Address
          </label>
          <input
            className="input wow fadeIn"
            data-wow-delay="0.7s"
            maxLength="256"
            name="_replyto"
            placeholder="What's your email address?"
            required="required"
            type="email"
          />
          <label
            className="field wow fadeIn"
            data-wow-delay="0.9s"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            className="input text-area wow fadeIn"
            data-wow-delay="0.9s"
            maxLength="5000"
            name="message"
            placeholder="How can I help you?"
          ></textarea>
          <input
            className="button submit wow fadeIn"
            data-wow-delay="1.3s"
            type="submit"
            value={`SEND`}
          />
        </form>
      </div>
    </div>
  );
};

export default Contact;
