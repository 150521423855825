import React from "react";

import Udemy from "./Udemy";
import Ibm from "./Ibm";
import Zello from "./Zello";
import Key from "./Key";
import Quant from "./Quant";

export const Filters = ["Companies", "Full Stack", "Mobile"];

export const Cards = [Udemy.card, Ibm.card, Zello.card, Key.card, Quant.card];

export const Components = {
  default: <h1>Oops. I don't remember working on this one. Let's try another card.</h1>,
  [Udemy.route]: <Udemy.component />,
  [Ibm.route]: <Ibm.component />,
  [Zello.route]: <Zello.component />,
  [Key.route]: <Key.component />,
  [Quant.route]: <Quant.component />,
};
