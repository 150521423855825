import React from "react";

const route = "udemy";
const offWhite = "#f8f8ff";
const color = "#a435f0";

const Udemy = () => {
  return (
    <div className="OverlayItem Udemy">
      <h1 style={{ color }}>Udemy</h1>
      <p className="position">
        <b>Software Engineer II</b>, October 2021 - February 2023
      </p>
      <p className="description">
        I was a Full-stack Software Engineer at{" "}
        <a
          style={{ color }}
          href="https://udemy.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Udemy
        </a>{" "}
        on the learner experience team. I was working primarily with React,
        TypeScript, GraphQL, Django, and our experimentation platform to drive
        higher conversion, retention, and customer satisfaction.
      </p>
      <p className="description no-justify">
        Some of the main responsibilities include:
      </p>
      <p className="description no-justify">
        <br />• Developed and implemented statistically significant A/B test
        variations to optimize user experiences, increase engagement, and drive
        revenue growth using React, TypeScript, GraphQL, Django, and internal
        experimentation platform
        <br />• Collaborated with product managers, designers, and stakeholders
        to identify areas of the product or user experience that could benefit
        from optimization
        <br />• Led the migration of an existing discovery page from monolithic
        to a globally distributed architecture framework based on Next.js as a
        server-generated page with backend services served over GraphQL
        <br />• Implemented and maintained Sentry and Datadog monitors and
        dashboards for our marketplace experience applications, ensuring
        seamless error and log monitoring, as well as performance tracking and
        analysis, to improve system reliability and user experience
      </p>
    </div>
  );
};

const udemyObject = {
  route,
  component: Udemy,
  card: {
    route,
    key: route,
    color: offWhite,
    title: "Udemy",
    tags: ["All", "Latest", "Companies", "Full Stack"],
    badge: "Company - Full Stack",
    description: "Software Engineer II",
  },
};

export default udemyObject;
